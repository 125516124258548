import React from "react"
import { Container, Row, Col } from "reactstrap"
import lightLogo from "../images/white-logo.svg"
import linkArrow from "../images/link-arrow.png"

const AboutCompany = ({ staffTitle, aboutCompany, companyLogo, title,pageLanguage }) => {
  const prefix = pageLanguage === 'et-et' ? `` : `${pageLanguage}/`;
  return(
    <React.Fragment>
      <Container>
        <Row className="pt-3">
          <Col
            className="text-right py-3"
            xs={{
              size: 8,
              offset: 4
            }}
            sm={{
              size: 5,
              offset: 7
            }}
          >
            <img 
              src={lightLogo}
              alt={title}
              style={{
                width: `40px`,
                height: `40px`,
                marginBottom: `0`
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col 
            className="text-right py-3"
            xs={{
              size: 11,
              offset: 1
            }}
          >
            {aboutCompany}
          </Col>
        </Row>
        <Row>
          <Col 
            className="text-right py-3"
            xs={{
              size: 11,
              offset: 1
            }}
          >
            <a className="font-weight-bold" href={`/${prefix}legalia`}>
              {title} 
              <img className="link-arrow" src={linkArrow} alt={title} />
            </a>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default AboutCompany