import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutCompany from "../components/aboutCompany"
const AboutPage = ({ data, path, pageContext }) => {
  const {
    meta_title, 
    meta_description, 
    company_logo, 
    about_title,
    about_company, 
    offices_title,
    offices,
    staff_title,
    _meta
  } = data.prismic.allHomes.edges[0].node
  return(
    <Layout 
      offices={offices} 
      path={path} 
      staffTitle={staff_title} 
      officesTitle={offices_title} 
      title={meta_title}
      aboutTitle={about_title}
      darkBackground={true}
      lang={_meta.lang}
      pageLanguage={pageContext.siteLanguage}
    uid={'about'}
    >
      <SEO title={meta_title} description={meta_description} />
      <section>
        <AboutCompany 
          aboutCompany={about_company} 
          companyLogo={company_logo} 
          title={meta_title} 
          staffTitle={staff_title} 
          pageLanguage={_meta.lang}
        />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutPage($locale: String!) {
    prismic {
      allHomes(lang: $locale) {
        edges {
          node {
            meta_title
            meta_description
            company_logo
            _meta { 
              lang
             }
            about_title
            about_company
            offices_title
            offices {
              postal_code
              eMail
              address
              telephone
              city
              country
            }
            staff_title
          }
        }
      }
    }
  }
`

export default AboutPage
